html, 
body { 
    height: 100vh; 
    width: 100%;
    margin: 0; 
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

body {
  background-image: url('/assets/background.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}

a,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: inherit;
  color: inherit;
}

h1, h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  color: rgb(0, 0, 150);
  text-shadow: 3px 2px 0px rgba(202,205,252,0.40);
}


* {
  box-sizing: border-box;
}


.debug-r {
  border: 1px solid red !important;
}
.debug-g {
  border: 1px solid green !important;
}
.debug-b {
  border: 1px solid blue !important;
}
.debug-y {
  border: 1px solid yellow !important;
}
.debug-p {
  border: 1px solid purple !important;
}
.debug-o {
  border: 1px solid orange !important;
}